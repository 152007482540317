exports.components = {
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-critical-materials-index-js": () => import("./../../../src/pages/critical-materials/index.js" /* webpackChunkName: "component---src-pages-critical-materials-index-js" */),
  "component---src-pages-data-index-js": () => import("./../../../src/pages/data/index.js" /* webpackChunkName: "component---src-pages-data-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-investors-index-js": () => import("./../../../src/pages/investors/index.js" /* webpackChunkName: "component---src-pages-investors-index-js" */),
  "component---src-pages-media-index-js": () => import("./../../../src/pages/media/index.js" /* webpackChunkName: "component---src-pages-media-index-js" */),
  "component---src-pages-news-contentful-post-year-year-contentful-post-slug-js": () => import("./../../../src/pages/news/{contentfulPost.year__year}/{contentfulPost.slug}.js" /* webpackChunkName: "component---src-pages-news-contentful-post-year-year-contentful-post-slug-js" */),
  "component---src-pages-news-contentful-year-year-js": () => import("./../../../src/pages/news/{contentfulYear.year}.js" /* webpackChunkName: "component---src-pages-news-contentful-year-year-js" */),
  "component---src-pages-projects-graal-index-js": () => import("./../../../src/pages/projects/graal/index.js" /* webpackChunkName: "component---src-pages-projects-graal-index-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */)
}

